<template>
  <div class="user-qualifications">
    <div class="user-qualifications_container">
      <!-- 认证状态 -->
      <div class="user-qualifications_container_certificationStatus">
        <div class="user-qualifications_container_certificationStatus_item" v-for="(item, index) in certifications" :key="index">
          <div class="user-qualifications_container_certificationStatus_item_left">
            <div class="user-qualifications_container_certificationStatus_item_left_img">
              <img v-show="item.verified && item.primary" src="../../assets/images/supplier/user/mainCertified.png" alt="">
              <img v-show="!item.verified && item.primary" src="../../assets/images/supplier/user/mainNotCertified.png" alt="">
              <img v-show="item.verified && !item.primary" src="../../assets/images/supplier/user/Certified.png" alt="">
              <img v-show="!item.verified && !item.primary" src="../../assets/images/supplier/user/notCertified.png" alt="">
            </div>
            <div :class="['user-qualifications_container_certificationStatus_item_left_content', item.verified ? '' : 'verified']">
              {{ item.name }}</div>
          </div>
          <div class="user-qualifications_container_certificationStatus_item_right">
            <img v-show="item.verified" src="../../assets/images/supplier/user/Certified1.png" alt="">
            <img v-show="!item.verified" src="../../assets/images/supplier/user/notCertified1.png" alt="">
          </div>
        </div>
      </div>

      <!-- 已上传的证书 -->
      <div class="user-qualifications_container_certificates">
        <div class="user-qualifications_container_certificates_title">Certificate uploaded</div>
        <div class="user-qualifications_container_certificates_content">
          <div class="user-qualifications_container_certificates_content_item" v-for="(item, index) in certificateList" :key="index">
            <!-- <img class="user-qualifications_container_certificates_content_item_img" :src="item.imageUrl" alt=""> -->
            <el-image class="user-qualifications_container_certificates_content_item_img" :src="item.imageUrl" :preview-src-list="certificatePreviewList">
            </el-image>
            <!--                        <div class="user-qualifications_container_certificates_content_item_pdf"
                            v-show="item.type == 'pdf'">
                            <div class="user-qualifications_container_certificates_content_item_pdf_img">
                                <img src="../../assets/images/supplier/user/pdf.png" alt="">
                                {{ item.name }}
                            </div>
                            <div class="user-qualifications_container_certificates_content_item_pdf_download">
                                <img src="../../assets/images/supplier/user/file_download.png" alt=""
                                    @click="file_download(item.url)">
                            </div>
                        </div>-->
            <div class="user-qualifications_container_certificates_content_item_delete" @click="deleteFile(item.supplierImageId)">
              delete</div>
          </div>
        </div>
      </div>

      <!-- 上传新证书 -->
      <div class="user-qualifications_container_upload">
        <!-- 使用Upload 拖拽上传文件 -->
        <el-upload class="upload-demo"  drag :action="uploadFileUrl" :headers="headers" :on-error="handleUploadError" :before-upload="beforeUpload" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :file-list="fileList"   accept=".jpg,.png,.JPG,.PNG,.jpeg" multiple>
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
        </el-upload>
      </div>
    </div>
  </div>
</template>

<script setup name="Qualifications">
import { ref } from 'vue'
import { addImages, delImage, getLoginInfo } from '@/api/user'
import { target } from '@/utils/URL'
import { getToken } from '@/utils/auth'
import { ElMessage, ElMessageBox } from 'element-plus'
const certifications = ref([
  { name: 'Sustainable Company Verified', verified: false, primary: false },
  { name: 'Full Customization', verified: true, primary: false },
  { name: 'Certificates Available', verified: false, primary: false }
])

// 图片地址
const certificatesData = ref([
  'https://www.szcfwj.net/images/uploadimg201908301645537451.jpg',
  'https://www.szcfwj.net/images/uploadimg201908301645537451.jpg',
  'http://www.gov.cn/zhengce/pdfFile/2023_PDF.pdf',
  'https://www.szcfwj.net/images/uploadimg201908301645537451.jpg',
  'http://www.gov.cn/zhengce/pdfFile/2023_PDF.pdf',
  'http://www.gov.cn/zhengce/pdfFile/2023_PDF.pdf'
])

const certificateList = ref([])

// 证书预览列表
const certificatePreviewList = ref([])
const supplier = ref()

const getUser = async () => {
  const res = await getLoginInfo()
  if (res.code === 200) {
    certificateList.value = res.data.supplier?.supplierImageList

    // 证书预览列表
    for (const e of certificateList.value) {
      certificatePreviewList.value.push(e.imageUrl)
    }
    console.log(certificatePreviewList.value, 'certificatePreviewList')
    supplier.value = res.data.supplier
  }
  if (certificateList.value.length > 0) {
    certifications.value[2].verified = true
    certifications.value[2].primary = true
  } else {
    certifications.value[2].verified = false
    certifications.value[2].primary = false
  }
  if (supplier.value.sustainableStatus) {
    if (supplier.value.sustainableStatus === '1') {
      certifications.value[0].verified = true
      certifications.value[0].primary = true
    }
  }
}
getUser()

console.log(certificateList.value)

// 上传文件

const uploadFileUrl = ref(target + '/common/upload') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})

const beforeUpload = (file) => {
  // const isLt2M = file.size / 1024 / 1024 < 2
  // if (!isLt2M) {
  //   ElMessage.error('上传头像图片大小不能超过 2MB!')
  // }
  // return isLt2M
  return true
}

const fileList = ref([])
const picUrls = ref([])

const handleRemove = (uploadFile, uploadFiles) => {
  picUrls.value = []
  for (const e of uploadFiles) {
    picUrls.value.push(e.response.url)
  }
}
const handleAvatarSuccess = async (response, uploadFile, uploadFiles) => {
  picUrls.value = []
  for (const e of uploadFiles) {
    picUrls.value.push(e.response.url)
  }
  // 调用添加供应商证书的接口
  const data = {
    picList: picUrls.value
  }
  const res = await addImages(data)
  if (res.code === 200) {
    fileList.value = []
    getUser()
  }

  certifications.value[2].verified = true
  certifications.value[2].primary = true
  console.log(picUrls.value)
}

// 下载文件
const file_download = (url) => {
  console.log('下载文件')
  // 根据链接下载文件
  window.open(url)
}

// 删除文件
const deleteFile = async (id) => {
  console.log('删除文件')
  const res = await delImage(id)
  if (res.code === 200) {
    fileList.value = []
    getUser()
  }
}

</script>

<style lang="scss" scoped>
.user-qualifications {
  padding: 30px;
  line-height: 1;

  &_container {
    &_certificationStatus {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      &_item {
        width: 360px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0;
        margin-bottom: 40px;

        &_left {
          display: flex;

          &_img {
            margin-right: 5px;
          }

          &_content {
            font-size: 16px;
            line-height: 1;
            color: rgba(39, 174, 96, 1);
          }

        }

        img {
          width: 16px;
          height: 16px;
        }
      }

      .verified {
        color: rgba(150, 150, 150, 1);
      }
    }

    // 已上传的证书
    &_certificates {
      margin-top: 20px;
      padding: 30px 0;
      border: 1px solid rgba(173, 173, 173, 0.3);
      border-left: none;
      border-right: none;

      &_title {
        font-size: 16px;
        color: #181818;
        font-weight: 700;
      }

      &_content {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;

        &_item {
          width: 160px;
          height: 230px;
          overflow: hidden;
          background-color: rgba(248, 248, 248, 1);
          border-radius: 8px;
          margin-right: 22px;
          margin-bottom: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;

          &:nth-child(5n) {
            margin-right: 0;
          }

          &_img {
            width: 160px;
            height: 230px;
            object-fit: cover;
          }

          &_pdf {
            width: 160px;
            height: 230px;
            object-fit: cover;
            font-size: 14px;
            color: rgba(24, 24, 24, 1);
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            &_img {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 60px;

              img {
                width: 30px;
                height: 30px;
                margin-bottom: 20px;
              }
            }

            &_download {
              margin-bottom: 15px;

              img {
                width: 15px;
                height: 15px;
              }
            }
          }

          &_delete {
            position: absolute;
            top: 0;
            right: 0;
            padding: 5px 8px;
            background-color: rgba(0, 0, 0, .06);
            border-radius: 0px 0px 0px 8px;
            color: rgba(0, 0, 0, .6);
            font-size: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
              color: rgba(0, 0, 0, .4);
            }
          }
        }

      }
    }

    &_upload {
      margin-top: 30px;

      :deep .el-upload-dragger {
        border: none;

        .el-upload__text {
          em {
            color: rgba(39, 174, 96, 1);
          }
        }
      }
    }
  }

}
</style>
