<template>
  <div class="add-product">
    <div class="add-product_container">
      <el-form :model="forms" :rules="rules1" ref="mainForm">

        <!-- 成品 半成品 -->
        <el-form-item class="property" label="">
          <el-radio-group v-model="forms.property">
            <el-radio label="0">Semi-finished Product</el-radio>
            <el-radio label="1">Finished Product</el-radio>
          </el-radio-group>
        </el-form-item>

        <!-- 产品名称 -->
        <el-form-item label="Product Name (English)" prop="productName">
          <el-input v-model="forms.productName" placeholder="Enter Product Name (English)"></el-input>
        </el-form-item>

        <el-form-item label="Product Name (Chinese)" prop="productNameChinese">
          <el-input v-model="forms.productNameChinese" placeholder="Enter Product Name (China)"></el-input>
        </el-form-item>

        <!-- Describe -->
        <el-form-item label="Description (Chinese)" prop="introductionChinese">
          <el-input v-model="forms.introductionChinese" placeholder="Enter Description" @blur="transMethod"></el-input>
        </el-form-item>

        <!-- Describe -->
        <el-form-item label="Description (English)" prop="productIntroduction">
          <el-input v-model="forms.productIntroduction" placeholder="Enter Description"></el-input>
        </el-form-item>

        <!-- classification -->
        <el-form-item label="Category" prop="categoryId">
          <el-select v-model="forms.categoryId" placeholder="Please select" @change="getMaterialsList">
            <el-option v-for="(item, index) in classifications" :key="index" :label="item.categoryName" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <!-- 上传图片 -->
        <el-form-item class="el-upload" label="Upload images" prop="images">
          <el-upload accept=".jpg,.png,.JPG,.PNG,.jpeg" :action="uploadFileUrl" list-type="picture-card" multiple :headers="headers" :on-error="handleUploadError" :before-upload="beforeUpload" :on-remove="handleRemove" :on-success="handleAvatarSuccess" :file-list="fileList">

            <el-icon>
              <Plus />
            </el-icon>
          </el-upload>
        </el-form-item>
      </el-form>

      <!-- sku -->
      <div class="add-product_container_sku" v-for="(item, index) in formSku" :key="index">
        <div class="add-product_container_sku_title">Sku{{ index + 1 }} <span v-if="index != 0" @click="deleteSku(index)">Delete</span>
        </div>

        <el-form :model="item" :rules="rules2" ref="formSku1">
          <!-- skuName -->
          <el-form-item label="SKU Code" prop="sukName">
            <el-input v-model="item.sukName" placeholder="Enter SKU Code"></el-input>
          </el-form-item>

          <!-- Unit -->
          <!--          <el-form-item label="Unit" prop="skuUnit">-->
          <!--            <el-select v-model="item.skuUnit" placeholder="Please select">-->
          <!--              <el-option v-for="(unit, unitIndex) in units" :key="unitIndex" :label="unit.dictLabel" :value="unit.dictValue"></el-option>-->
          <!--            </el-select>-->
          <!--          </el-form-item>-->

          <!-- Unit specification size -->
          <el-form-item label="Unit Specification" prop="specification">
            <el-input v-model="item.specification" placeholder="Enter Unit Specification"></el-input>
          </el-form-item>

          <!-- Material 下拉选择 -->
          <el-form-item label="Material" prop="materialId">
            <el-select v-model="item.materialId" placeholder="Please select">
              <el-option v-for="(material, materialIndex) in materials" :key="materialIndex" :label="material.content" :value="material.id"></el-option>
            </el-select>
          </el-form-item>

          <!-- Unit weight -->
          <el-form-item label="Unit Weight (g)" prop="weight">
            <el-input v-model="item.weight" placeholder="Enter numbers only" type="number"></el-input>
          </el-form-item>

          <el-form-item label="Currency Type" prop="currencyType">
            <el-select v-model="item.currencyType" placeholder="Select Currency Preference">
              <el-option v-for="(item, index) in currencyPreference" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <!-- Price(CNY) -->
          <el-form-item label="Price" prop="skuPrice">
            <el-input v-model="item.skuPrice" placeholder="Enter numbers only" type="number"></el-input>
          </el-form-item>

          <!-- HS Code -->
          <el-form-item label="HS Code" prop="skuHsCode">
            <el-input v-model="item.skuHsCode" placeholder="Enter HS Code"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="add-product_container_sku_add" @click="addSku">Add Sku</div>

      <div class="btn">
        <el-button class="save" @click="submitForm">Save</el-button>
      </div>
    </div>
    <el-dialog v-model="dialogVisible">
      <img w-full :src="dialogImageUrl" alt="Preview Image" />
    </el-dialog>
  </div>
</template>

<script setup name="AddProduct">
import { Plus } from '@element-plus/icons-vue'
import { ref, inject, defineEmits, getCurrentInstance } from 'vue'
import { addProduct, getProductTypeList } from '@/api/product'
import { getToken } from '@/utils/auth'
import { target } from '@/utils/URL'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
import { getMaterialList, getMaterialListByCatalog, getSysDictList } from '@/api/user'
import { trans } from '@/api/common'
const router = useRouter()
const uploadFileUrl = ref(target + '/common/upload') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})
// 商品的分类
const classifications = ref([])
// 获取商品类别
const getProductType = async () => {
  const res = await getProductTypeList()
  if (res.code === 200) {
    classifications.value = res.data
  }
}
getProductType()

const currencyPreference = ref(['USD', 'EUR', 'CNY'])

// 表单验证
const rules1 = ref({
  productName: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  productNameChinese: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  introductionChinese: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  productIntroduction: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  categoryId: [
    { required: true, message: 'Required', trigger: 'blur' }
  ]
})
const rules2 = ref({
  sukName: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  // skuUnit: [
  //   { required: true, message: 'Required', trigger: 'blur' }
  // ],
  currencyType: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  specification: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  materialId: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  weight: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  skuPrice: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  skuHsCode: [
    { required: true, message: 'Required', trigger: 'blur' }
  ]
})

const instance = getCurrentInstance()
const validateForm1 = () => {
  const mainForm = instance.refs.mainForm
  return new Promise((resolve, reject) => {
    mainForm.validate((valid) => {
      if (valid) {
        // 表单1验证通过
        resolve()
      } else {
        // 表单1验证未通过
        reject('Form 1 validation failed')
      }
    })
  })
}

const validateForm2 = () => {
  return new Promise((resolve, reject) => {
    const formSku1 = instance.refs.formSku1
    // 循环
    const promises = formSku1.map((item) => {
      return new Promise((resolve, reject) => {
        item.validate((valid) => {
          if (valid) {
            // 表单2验证通过
            resolve()
          } else {
            // 表单2验证未通过
            reject('Form 2 validation failed')
          }
        })
      })
    })
    Promise.all(promises)
      .then(() => {
        resolve() // 所有表单验证通过
      })
      .catch((error) => {
        reject(error) // 有表单验证未通过
      })
  })
}

// 上面是表单验证

// 单位列表
const units = ref([])
// 获取商品类别
const getUnitType = async () => {
  const res = await getSysDictList({ type: 'eko_jldw' })
  if (res.code === 200) {
    units.value = res.data
  }
}
getUnitType()

// 材质列表
const materials = ref([])
// 获取材质列表
const getMaterialsList = async () => {
  if (!forms.value.categoryId) {
    return
  }
  const classification = classifications.value.find(item => item.id === forms.value.categoryId)
  console.log(classification)
  const classificationName = classification?.categoryName
  const q = {
    catalog1: classificationName
  }
  const res = await getMaterialListByCatalog(q)
  if (res.code === 200) {
    materials.value = res.data
  }
}
// getMaterialsList()

const beforeUpload = (file) => {
  // const isLt2M = file.size / 1024 / 1024 < 2
  // if (!isLt2M) {
  //   ElMessage.error('上传头像图片大小不能超过 2MB!')
  // }
  // return isLt2M
  return true
}

const fileList = ref([])
const pictures = ref([])
const handleRemove = (uploadFile, uploadFiles) => {
  pictures.value = []
  for (const e of uploadFiles) {
    pictures.value.push(e.response.url)
  }
}
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  pictures.value = []
  for (const e of uploadFiles) {
    pictures.value.push(e.response.url)
  }
  console.log(pictures.value)
}
// 上传图片结束

const forms = ref({
  property: '0',
  productName: '',
  productNameChinese: '',
  productIntroduction: '',
  introductionChinese: '',
  categoryId: null,
  pictures: [],
  supplierProductSkuList: []
})

const formSku = ref([{
  sukName: '',
  skuUnit: '',
  specification: '',
  materialId: '',
  weight: '',
  skuPrice: '',
  skuHsCode: ''
}])

const emit = defineEmits(['customEvent'])

// 删除一个sku
const deleteSku = (index) => {
  formSku.value.splice(index, 1)
}
// 添加一个sku
const addSku = () => {
  const newSku = {
    id: null,
    sukName: '',
    skuUnit: null,
    specification: '',
    materialId: null,
    weight: '',
    skuPrice: '',
    skuHsCode: ''
  }
  formSku.value.push(newSku)
}

const dialogImageUrl = ref('')
const dialogVisible = ref(false)

const submitForm = async () => {
  Promise.all([validateForm1(), validateForm2()]).then(async () => {
    // 两个表单都验证通过，执行提交操作
    forms.value.pictures = pictures.value
    forms.value.supplierProductSkuList = formSku.value
    if (forms.value.pictures.length === 0) {
      ElMessage.error('please upload pictures')
      return
    }
    if (forms.value.supplierProductSkuList == []) {
      ElMessage.error('please input sku')
      return
    }
    const result = await addProduct(forms.value)
    if (result.code === 200) {
      emit('customEvent', 'close')
    }
  }).catch((error) => {
    // ElMessage.error('Form validation failed: ' + error)
    // console.log('验证失败')
  })
}

// 接收父组件传递的数据
const counter = inject('counter')

const transMethod = async () => {
  if (forms.value.introductionChinese) {
    const res = await trans(forms.value.introductionChinese)
    if (res.code === 200) {
      forms.value.productIntroduction = res.data
    }
  }
}

</script>

<style lang="scss" scoped>
@use '../../assets/scss/elForm.scss' as *;

:deep .el-form .el-form-item {
  width: 530px;
}

.add-product {
  padding: 30px;
  line-height: 1;

  &_container {
    .el-btn {
      width: 830px;
      justify-content: center;
      align-items: center;
      margin: 30px auto 30px !important;
    }

    .property {
      width: 100%;
    }

    .el-upload {
      width: 830px;
      align-items: flex-start;

      :deep .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete {
        // position: absolute;
        font-size: inherit;
        color: white;
        // top: 68px;
      }

      :deep .el-upload-list--picture-card .el-upload-list__item-actions span+span {
        margin: 0;
      }

      :deep .el-upload-list--picture-card .el-upload-list__item-actions {
        display: flex;
      }

      :deep .el-upload-list__item-preview {
        display: none;
      }
    }

    &_sku {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &_title {
        font-size: 14px;
        width: 100%;
        margin: 20px 0;
        padding-bottom: 10px;
        border-bottom: 1px solid #e3e3e3;
        display: flex;
        justify-content: space-between;

        span {
          color: rgba(39, 174, 96, 1);
        }
      }

      &_add {
        font-size: 14px;
        width: 100%;
        margin: 20px 0;
        padding-bottom: 10px;
        color: rgba(39, 174, 96, 1);
      }
    }

    .btn {
      display: flex;
      align-items: center;

      .save {
        width: 80px;
        height: 40px;
        background-color: #27ae60;
        color: #fff;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        position: relative;
        margin: 20px auto;
      }
    }
  }
}

/* :deep .el-upload-list--picture-card .el-upload-list__item-actions .el-upload-list__item-delete {
     position: absolute;
    font-size: inherit;
    color: white;
} */
</style>
