<template>
  <div class="user-product">
    <div class="user-product_container">
      <div class="user-product_container_tab">
        <div v-for="(item, index) in topTab" :key="index" :class="['user-product_container_tab_item', item.active === true ? 'user-product_container_tab_active' : '']" @click="changeTab(index)">
          {{ item.title }}
        </div>
      </div>

      <!-- 产品列表 -->
      <div class="user-product_container_list" v-show="activeTab === 'All'">
        <div class="user-product_container_list_item" v-for="(item, i) in list" :key="i" @click="toProductDetail(item.productId)">
          <div class="user-product_container_list_item_img">
            <img :src="item?.productImageList[0]?.imageUrl" alt="">
          </div>
          <div class="user-product_container_list_item_info">
            <div class="user-product_container_list_item_info_title">{{ item.productName }}</div>
            <div class="user-product_container_list_item_info_carbon">
              <!--                            <img src="../../assets/images/home/carbonFootprint.png" alt="">-->
              <!--                            <span class="user-product_container_list_item_info_carbon_value">- g</span>-->
              <!--                            <span class="user-product_container_list_item_info_carbon_co">CO<sub>2</sub></span>-->
              <el-button class="user-product_container_list_item_info_carbon_co" @click.stop="editProduct(item.productId)">Edit</el-button>
              <el-button class="user-product_container_list_item_info_carbon_del" @click.stop="delProductMethod(item.productId)">Delete</el-button>
            </div>
            <div class="user-product_container_list_item_info_title">

            </div>
          </div>
        </div>
      </div>

      <!-- 下架产品 -->
      <div class="user-product_container_list" v-show="activeTab === 'Unavailable'">
        <div class="user-product_container_list_item" v-for="(item, i) in list" :key="i" @click="toProductDetail(item.productId)">
          <div class="user-product_container_list_item_img">
            <img :src="item?.productImageList[0]?.imageUrl" alt="">
          </div>
          <div class="user-product_container_list_item_info">
            <div class="user-product_container_list_item_info_title">{{ item.productName }}</div>
            <div class="user-product_container_list_item_info_carbon">
              <!--                            <img src="../../assets/images/home/carbonFootprint.png" alt="">-->
              <!--                            <span class="user-product_container_list_item_info_carbon_value">526.02</span>-->
              <!--                            <span class="user-product_container_list_item_info_carbon_co">CO<sub>2</sub></span>-->
            </div>
          </div>
        </div>
      </div>

      <!-- 分页 -->
      <div class="user-product_container_page">
        <el-pagination :page-size="query.pageSize" @current-change="currentChange" @prev-click="upPage" @next-click="downPage" :current-page="currentPage" layout="prev, pager, next" :total="total">
        </el-pagination>
      </div>

      <!-- 总数 -->
      <div class="user-product_container_total">
        <span class="user-product_container_total_text">Total</span>
        <span class="user-product_container_total_num">{{ total }}</span>
      </div>
    </div>

  </div>
</template>

<script setup name="Product">
import { ref, defineProps, defineEmits } from 'vue'
import { getProductListCenter, delProduct } from '@/api/product'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox } from 'element-plus'
const router = useRouter()
const route = useRoute()
const props = defineProps({
  search: String
})
const emit = defineEmits(['searchData', 'customEvent'])
const currentChange = (val) => {
  query.value.pageNum = val
  onLoad()
}
const upPage = () => {
  query.value.pageNum--
  onLoad()
}
const downPage = () => {
  query.value.pageNum++
  onLoad()
}
const total = ref(0)
const list = ref({})
const query = ref({})
query.value = { pageNum: 1, pageSize: 9, status: 1 }
const activeTab = ref('All')
const onLoad = async (search) => {
  query.value.productName = search
  if (activeTab.value === 'All') {
    query.value.status = 1
  } else {
    query.value.status = 0
  }
  const response = await getProductListCenter(query.value)
  if (response.rows.length > 0) {
    console.log(response, '产品列表')
    list.value = response.rows
    total.value = response.total
  } else {
    list.value = {}
    total.value = 0
  }
}
onLoad()

// 编辑产品
const editProduct = (productId) => {
  emit('customEvent', { type: 'openEdit', productId: productId })
  console.log('编辑产品')
}

const delProductMethod = async (productId) => {
  ElMessageBox.confirm(
    'Are you sure delete this product?',
    'Delete this product',
    {
      distinguishCancelAndClose: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      autofocus: false
    }
  )
    .then(async () => {
      // 确认退出登录
      const data = {
        productId: productId
      }
      const response = await delProduct(data)
      if (response) {
        onLoad()
      }
    })
    .catch(() => {

    })
}


// 记录当前的激活tab

// 顶部tab切换
const topTab = ref([
  {
    title: 'All',
    active: true
  },
  {
    title: 'Unavailable',
    active: false
  }
])

// 点击切换
const changeTab = (index) => {
  topTab.value.forEach((item, i) => {
    if (i === index) {
      item.active = true
      activeTab.value = item.title
    } else {
      item.active = false
    }
  })
  onLoad()
}

const toProductDetail = (productId) => {
  console.log('toProductDetail:' + productId)
  router.push({
    path: 'productDetail',
    query: {
      productId,
      userType: 'ProductOwner'
    }
  })
}
defineExpose({
  onLoad
})

</script>

<style lang="scss" scoped>
.user-product {
  padding: 20px 30px 30px 30px;
  line-height: 1;

  &_container {
    &_tab {
      display: flex;

      &_item {
        font-size: 14px;
        line-height: 16px;
        color: rgba(39, 174, 96, 1);
        background-color: rgba(39, 174, 96, 0.06);
        border-radius: 4px;
        padding: 10px 15px;
        cursor: pointer;

        &:hover {
          color: #ffffff;
          background-color: rgba(39, 174, 96, 1);
        }

        &:first-child {
          margin-right: 15px;
        }
      }

      &_active {
        color: #ffffff;
        background-color: rgba(39, 174, 96, 1);
      }
    }

    &_list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      justify-content: space-between;

      &_item {
        width: 280px;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 30px;
        cursor: pointer;

        &_img {
          width: 100%;
          height: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 0;

          img {
            width: 100%;
            height: 160px;
            object-fit: cover;
          }
        }

        &_info {
          margin: 20px;

          &_title {
            font-size: 16px;
            line-height: 16px;
            font-weight: 700;
            color: rgba(24, 24, 24, 1);
          }

          &_carbon {
            display: flex;
            align-items: center;
            margin-top: 15px;
            justify-content: flex-end;

            img {
              width: 16px;
              height: 16px;
              margin-right: 10px;
            }

            &_value {
              font-size: 16px;
              line-height: 16px;
              color: rgba(39, 174, 96, 1);
              font-weight: 700;
            }

            &_co {
              font-size: 14px;
              line-height: 16px;
              color: rgba(39, 174, 96, .8);
              margin-left: 5px;
            }

            &_del {
              font-size: 14px;
              line-height: 16px;
              color: red;
              margin-left: 5px;
            }

            sub {
              font-size: 12px;
              line-height: 12px;
            }
          }
        }
      }

      &::after {
        content: '';
        width: 280px;
      }
    }

    // 分页
    &_page {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }

    :deep .el-pager li.is-active {
      color: rgba(39, 174, 96, 1);
      background-color: rgba(39, 174, 96, .1);
    }

    :deep .el-pager li:hover {
      color: rgba(39, 174, 96, 1);
      background-color: rgba(39, 174, 96, .1);
    }

    // 总数
    &_total {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      font-size: 12px;
      color: rgba(173, 173, 173, 1);
      line-height: 1;

      &_text {
        margin-right: 5px;
      }

      &_num {
        color: rgba(39, 174, 96, 1);
      }
    }

  }

}
</style>
