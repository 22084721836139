<template>
  <div class="customer-information">
    <div class="customer-information_company">
      <div class="customer-information_company_left">
        <div class="customer-information_company_left_img"><img :src="avatar" alt="">
        </div>
        <div class="customer-information_company_left_title">{{ forms.corporateName }}</div>
      </div>
      <div class="customer-information_company_right">
        <div class="customer-information_company_right_btn" @click="updateAvatar">
          Change Profile Picture
        </div>
      </div>
    </div>

    <!-- 用户信息表单 -->
    <div class="customer-information_form">
      <div class="customer-information_form_title">
        Basic Information
      </div>
      <div class="customer-information_form_body">
        <el-form ref="form" :rules="rules" :model="forms" @submit.native.prevent="submitForm">
          <!-- Corporate Name -->
          <el-form-item label="Corporate Name (Chinese)" prop="corporateNameChinese">
            <el-input v-model="forms.corporateNameChinese" placeholder="Enter Corporate Name(China)" @blur="transMethod"></el-input>
          </el-form-item>

          <el-form-item label="Corporate Name (English)" prop="corporateName">
            <el-input v-model="forms.corporateName" placeholder="Enter Corporate Name(English)"></el-input>
          </el-form-item>

          <!-- Company Address -->
          <el-form-item label="Company Address" prop="companyAddress">
            <el-input v-model="forms.companyAddress" placeholder="Enter Company Address"></el-input>
          </el-form-item>

          <el-form-item label="Landline" prop="companySwitchboard">
            <el-input v-model="forms.companySwitchboard" placeholder="Enter Landline"></el-input>
          </el-form-item>
          <el-form-item label="Contact Name" prop="contacts">
            <el-input v-model="forms.contacts" placeholder="Enter Contact Name"></el-input>
          </el-form-item>
          <el-form-item label="Contact Email" prop="contactEmail">
            <el-input v-model="forms.contactEmail" placeholder="Enter Contact Email"></el-input>
          </el-form-item>

          <el-form-item label="Contact Phone" prop="contactPhone">
            <el-input v-model="forms.contactPhone" placeholder="Enter Contact Phone"></el-input>
          </el-form-item>
          <el-form-item label="Contact WeChat" prop="contactWeChat">
            <el-input v-model="forms.contactWeChat" placeholder="Enter Contact WeChat"></el-input>
          </el-form-item>
          <el-form-item label="Shipping Warehouse Address" prop="shippingWarehouseAddress">
            <el-input v-model="forms.shippingWarehouseAddress" placeholder="Enter Shipping warehouse address"></el-input>
          </el-form-item>

          <!-- Shipping Port Preference的下拉选择 -->
          <el-form-item label="Shipping Port Preference" prop="shippingPortPreference">
            <el-select v-model="forms.shippingPortPreference" placeholder="Select Shipping Port Preference">
              <el-option v-for="(item, index) in shippingPortPreference" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="Shipping Port Preference" prop="shippingPortPreference">
            <el-input v-model="forms.shippingPortPreference" placeholder="Enter Shipping Port Preference"></el-input>
          </el-form-item> -->

          <!-- Currency Preference -->
          <el-form-item label="Currency Preference" prop="currencyPreference">
            <el-select v-model="forms.currencyPreference" placeholder="Select Currency Preference">
              <el-option v-for="(item, index) in currencyPreference" :key="index" :label="item" :value="item"></el-option>
            </el-select>
          </el-form-item>

          <!-- Established in -->
          <el-form-item label="Established In" prop="establishedIn">
            <el-date-picker v-model="forms.establishedIn" type="date" placeholder="Select Established in" format="YYYY-MM-DD" value-format="YYYY-MM-DD"></el-date-picker>
          </el-form-item>

          <!-- Company size (number of people) -->
          <el-form-item label="Company Size (No. of Employee)" prop="companySize">
            <el-input v-model="forms.companySize" placeholder="Enter Company size (number of people)" type="number"></el-input>
          </el-form-item>

          <!-- 选择Industry -->
          <el-form-item label="Industry" prop="industry">
            <el-input v-model="forms.industry" placeholder="Enter Industry"></el-input>
          </el-form-item>

          <!-- 多选 Mainly covering the market -->
          <el-form-item class="container_signUp_companyInformation_input_select" label="Export Market" prop="mainlyCoveringTheMarket">
            <el-input v-model="forms.mainlyCoveringTheMarket" placeholder="Enter Mainly covering the market"></el-input>
          </el-form-item>

          <!-- Company products -->
          <el-form-item label="Company Products" prop="companyProducts">
            <el-input type="textarea" v-model="forms.companyProducts" placeholder="Enter Company products"></el-input>
          </el-form-item>

          <el-form-item class="el-btn">
            <el-button type="primary" @click="updateUserInfo">Save</el-button>
          </el-form-item>
        </el-form>
      </div>

    </div>

    <!--      换头像弹窗-->
    <el-dialog v-model="dialogVisible" title="Change Profile Picture">
      <el-upload class="avatar-uploader" :action="uploadFileUrl" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeUpload" :headers="headers" multiple>
        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
        <el-icon v-else class="avatar-uploader-icon">
          <Plus />
        </el-icon>
      </el-upload>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancelAvatar">Cancel</el-button>
          <el-button type="primary" @click="submitAvatar">
            Confirm
          </el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script setup name="SupplierInformation">
import { reactive, ref } from 'vue'
import { getLoginInfo, updateUserBaseInfo, updateUserAvatar } from '@/api/user'
import { getMuDidiPortList } from '@/api/transport'
import { ElMessage } from 'element-plus'
import { Plus } from '@element-plus/icons-vue'
import { getToken } from '@/utils/auth'
import { target } from '@/utils/URL'
import { trans } from '@/api/common'
const avatar = ref('https://shuahuo-oss.oss-cn-beijing.aliyuncs.com/co-track/head.png')
const imageUrl = ref('')
const uploadFileUrl = ref(target + '/common/upload') // 上传的图片服务器地址
const headers = ref({
  Authorization: getToken()
})

const dialogVisible = ref(false)

// step3 供应商注册
const forms = ref({
  corporateName: '',
  corporateNameChinese: '',
  companyAddress: '',
  companySwitchboard: '',
  contacts: '',
  contactEmail: '',
  contactPhone: '',
  contactWeChat: '',
  shippingWarehouseAddress: '',
  shippingPortPreference: '',
  currencyPreference: '',
  establishedIn: '',
  companySize: '',
  industry: '',
  mainlyCoveringTheMarket: '',
  companyProducts: ''
})

// 港口偏好
const shippingPortPreference = ref([
  '丹东港', '大连港', '营口港', '锦州港', '秦皇岛港', '通许港', '天津港', '黄骅港', '龙口港', '烟台港', '威海港', '石岛港', '张家埠港', '青岛港', '日照港', '兰山港', '连云港', '南通港', '张家港港', '张家港', '南京港', '上海港', '诸暨港', '宁波港', '舟山港', '海门港', '温州港', '赛岐港', '福州港', '泉州港', '厦门港', '东山港', '汕头港', '汕尾港', '汕甚港', '广州港', '中山港', '珠海港', '江门港', '阳江港', '水东港', '湛江港', '侠海港', '海安港', '北海港', '防城港', '钦州港', '海口港', '神州港', '巴梭港', '阳浦港', '高雄港', '花莲港', '台中港', '基隆港'
])

const rules = ref({
  corporateName: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  corporateNameChinese: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companyAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companySwitchboard: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contacts: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactEmail: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactPhone: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  contactWeChat: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  shippingWarehouseAddress: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  shippingPortPreference: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  currencyPreference: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  establishedIn: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companySize: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  industry: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  mainlyCoveringTheMarket: [
    { required: true, message: 'Required', trigger: 'blur' }
  ],
  companyProducts: [
    { required: true, message: 'Required', trigger: 'blur' }
  ]
})

const getUser = async () => {
  const res = await getLoginInfo()
  if (res.code === 200) {
    forms.value = res.data.supplier
    forms.value.corporateName = res.data.supplier.supplierName
    forms.value.corporateNameChinese = res.data.supplier.supplierNameChinese
    forms.value.companyAddress = res.data.supplier.supplierAddress
    forms.value.companySwitchboard = res.data.supplier.supplierCompanySwitchboard
    forms.value.contacts = res.data.supplier.supplierContact
    forms.value.contactEmail = res.data.supplier.supplierEmail
    forms.value.contactPhone = res.data.supplier.supplierContactNumber
    forms.value.contactWeChat = res.data.supplier.supplierWechat
    forms.value.shippingWarehouseAddress = res.data.supplier.deliveryWarehouseAddress
    forms.value.shippingPortPreference = res.data.supplier.portOfDispatchIds
    forms.value.currencyPreference = res.data.supplier.supplierPaymentCurrency
    forms.value.companySize = res.data.supplier.supplierTotalEmployee
    forms.value.mainlyCoveringTheMarket = res.data.supplier.supplierMainMarket
    forms.value.industry = res.data.supplier.supplierIndustry
    forms.value.companyProducts = res.data.supplier.companyProducts
    forms.value.establishedIn = res.data.supplier.establishedIn
    if (res.data.sysUser?.avatar != null && res.data.sysUser?.avatar !== '') {
      avatar.value = res.data.sysUser.avatar
    }
  }
}
getUser()

const updateUserInfo = () => {
  forms.value.type = '1'
  const res = updateUserBaseInfo(forms.value)
  if (res.code === 200) {

  }
}

const currencyPreference = ref(['USD', 'EUR', 'CNY'])

const submitForm = () => {
  console.log(forms.value)
}

const beforeUpload = (file) => {
  // const isLt2M = file.size / 1024 / 1024 < 2
  // if (!isLt2M) {
  //   ElMessage.error('上传头像图片大小不能超过 2MB!')
  // }
  // return isLt2M
  return true
}

const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  imageUrl.value = response.url
}

// 打开换头像
const updateAvatar = () => {
  imageUrl.value = avatar.value
  dialogVisible.value = true
}
// 换头像取消
const cancelAvatar = () => {
  dialogVisible.value = false
  imageUrl.value = null
}

const submitAvatar = async () => {
  const data = {
    avatar: imageUrl.value
  }
  const res = await updateUserAvatar(data)
  if (res.code === 200) {
    dialogVisible.value = false
    avatar.value = imageUrl.value
  }
}

const transMethod = async () => {
  if (forms.value.corporateNameChinese) {
    const res = await trans(forms.value.corporateNameChinese)
    if (res.code === 200) {
      forms.value.corporateName = res.data
    }
  }
}
</script>

<style lang="scss" scoped>
@use '../../assets/scss/elForm.scss' as *;

.customer-information {
  padding: 30px;

  &_company {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(248, 248, 248, 0.8);
    padding: 25px 30px;
    border-radius: 8px;

    &_left {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      &_img {
        img {
          width: 60px;
          height: 60px;
        }
      }

      &_title {
        font-size: 16px;
        color: #181818;
        margin-left: 20px;
      }
    }

    &_right {
      &_btn {
        background-color: rgba(39, 174, 96, 0.04);
        padding: 15px 30px;
        color: #27ae60;
        border: 1px solid #27ae60;
        font-size: 14px;
        border-radius: 6px;
      }
    }
  }

  &_form {
    margin-top: 50px;

    &_title {
      font-size: 16px;
      color: #181818;
      font-weight: 700;
    }

    &_body {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &_subbtn {
        background-color: rgba(39, 174, 96, 0.04);
        padding: 15px 30px;
        color: #27ae60;
        border: 1px solid #27ae60;
        font-size: 14px;
        border-radius: 6px;
        width: 85px;
        text-align: center;
        margin: 50px auto 30px;
      }

    }

    .el-btn {
      width: 830px;
      justify-content: center;
      align-items: center;
      margin: 30px auto 30px !important;
    }

    .el-upload {
      width: 830px;
      align-items: flex-start;
    }

    :deep .el-date-editor.el-input {
      width: 530px;

      .el-input__wrapper {
        padding: 25px 20px;
      }
    }
  }
}
</style>
<style scoped>
.avatar-uploader {
  text-align: center;
}

.avatar-uploader .avatar {
  width: 278px;
  height: 278px;
  display: block;
}
</style>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
