<template>
  <div class="container">
    <div class="container_main">
      <div class="container_main_left">
        <div class="container_main_left_title">Account</div>
        <div class="container_main_left_tab">
          <div v-for="(item, index) in leftTab" :key="index" :class="['container_main_left_tabtitle', item.active === true ? 'container_main_left_tabtitle_active' : '']" @click="changeTab(index)" v-show="item.vShow">
            {{ item.title }}
          </div>
        </div>
      </div>

      <div class="container_main_right">
        <div class="container_main_right_title">{{ !isAdd && !isEdit ? activeTab : isAdd ? 'Add Product' : 'Edit Product' }}
          <div class="container_main_right_title_left" v-show="leftTab[0].active && activeTab == 'Product' && userType == 1">
            <el-input v-show="!isAdd && !isEdit" class="container_main_right_title_left_search" placeholder="Search" v-model="search" @keyup.enter.native="searchData"></el-input>
            <el-button class="container_main_right_title_left_add" @click="addProduct" v-if="showButton">{{ isAdd || isEdit ? 'Save' : 'Add' }}</el-button>
          </div>
        </div>

        <!-- 供应商 - 产品 -->
        <Product ref="refProduct" :searchData="searchData" :productName="search" v-if="userType == 1 && leftTab[0].active && activeTab == 'Product' && !isAdd && !isEdit" @customEvent="openEditProduct" />

        <!-- 供应商 - 添加产品 -->
        <AddProduct v-if="userType == 1 && leftTab[0].active && activeTab == 'Product' && isAdd" @customEvent="closeAddProduct" />
        <!--        <AddProductCopy v-show="userType == 1 && leftTab[0].active && activeTab == 'Product' && isAdd" />-->
        <EditProduct v-if="userType == 1 && leftTab[0].active && activeTab == 'Product' && isEdit" @customEvent="closeEditProduct" :productId="productId" />

        <!-- 供应商 - 用户信息 -->
        <SupplierInformation v-if="userType == 1 && leftTab[1].active && activeTab == 'Profile'" />

        <!-- 客户 - 用户信息 -->
        <CustomerInformation v-if="userType == 2 && leftTab[1].active && activeTab == 'Profile'" />

        <!-- 资质 -->
        <Qualifications v-if="userType == 1 && leftTab[2].active && activeTab == 'Certificates'" />

        <!-- 密码 -->
        <Password v-if="leftTab[3].active && activeTab == 'Password'" />

      </div>
    </div>

    <Foot />
  </div>
</template>

<script setup name="supplierCenter">
import { ref, provide, reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'

import Foot from '../../components/Foot.vue'

// 供应商 - 产品
import Product from '../../components/userCenter/Product.vue'

// 供应商 - 添加产品
import AddProduct from '../../components/userCenter/AddProduct.vue'

import EditProduct from '../../components/userCenter/EditProduct.vue'

// 供应商 - 用户信息
import SupplierInformation from '../../components/userCenter/SupplierInformation.vue'

// 用户信息
import CustomerInformation from '../../components/userCenter/CustomerInformation.vue'

// 供应商 - 资质
import Qualifications from '../../components/userCenter/Qualifications.vue'

// 密码
import Password from '../../components/userCenter/Password.vue'
import { removeToken, setUserId } from '@/utils/auth'
const router = useRouter()
const route = useRoute()
const showButton = ref(true)
const index = route.query.index
/* onMounted(() => {
  checkIndex()
  if (userType.value === 1) {
    changeTab(0)
  } else {
    changeTab(1)
  }
}) */
// 左侧tab切换
const leftTab = ref([
  {
    title: 'Product',
    active: true,
    vShow: true
  },
  {
    title: 'Profile',
    active: false,
    vShow: true
  },
  {
    title: 'Certificates',
    active: false,
    vShow: true

  },
  {
    title: 'Password',
    active: false,
    vShow: true

  },
  {
    title: 'Logout',
    active: false,
    vShow: true

  }
])

// 记录当前的激活tab
const activeTab = ref('Product')
// 获取路由参数

const productType = route.query.type
// const user = route.query.user
// 用户身份 1-供应商 2-客户
const userType = route.query.userType
console.log('产品详情传递过来的type', productType)
const counter = reactive({ value: '传给添加商品的数据' })
// 当前是否点击了添加按钮
const isAdd = ref(false)
const isEdit = ref(false)
const productId = ref()
if (productType === 'add') {
  isAdd.value = true
  // 使用 provide 函数向子组件提供数据
  provide('counter', counter)
}
const checkIndex = () => {
  console.log(userType)
  if (index != null) {
    changeTab(index)
  }
  if (userType == 1) {
    leftTab.value[0].vShow = true
    leftTab.value[1].vShow = true
    leftTab.value[2].vShow = true
    leftTab.value[3].vShow = true
    leftTab.value[4].vShow = true
    activeTab.value = 'Product'
    leftTab.value[0].active = true
  } else {
    leftTab.value[0].vShow = false
    leftTab.value[1].vShow = true
    leftTab.value[2].vShow = false
    leftTab.value[3].vShow = true
    leftTab.value[4].vShow = true
    activeTab.value = 'Profile'
    leftTab.value[1].active = true
  }
}
checkIndex()

// 点击切换
const changeTab = (index) => {
  leftTab.value.forEach((item, i) => {
    if (i === index) {
      if (item.title === 'Logout') {
        logout()
      } else {
        item.active = true
        // 点击的不是退出登录，进行记录
        activeTab.value = item.title
        isAdd.value = false
        isEdit.value = false
        showButton.value = true
      }
    } else {
      item.active = false
      isAdd.value = false
      isEdit.value = false
      showButton.value = true
    }
  })
}

// 退出确认
const logout = () => {
  ElMessageBox.confirm(
    'Are you sure you want to log out?',
    'Exit login prompt',
    {
      distinguishCancelAndClose: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
      autofocus: false
    }
  )
    .then(() => {
      // 确认退出登录
      removeToken()
      setUserId(null)
      router.push({
        path: '/'
      })
    })
    .catch(() => {
      //  取消退出登录
      // 激活上一个按钮
      leftTab.value.forEach((item, i) => {
        if (item.title === activeTab.value) {
          item.active = true
        } else {
          item.active = false
        }
      })
    })
}

// 搜索
const refProduct = ref(null)
const search = ref('')
const searchData = () => {
  console.log(search.value)
  refProduct.value.onLoad(search.value)
}

// 添加产品
const addProduct = () => {
  isAdd.value = true
  isEdit.value = false
  console.log('添加产品')
  showButton.value = false
}

// 关闭添加产品的弹窗
const closeAddProduct = (data) => {
  if (data === 'close') {
    isAdd.value = false
    console.log('关闭添加产品')
    showButton.value = true
  }
}

// 打开编辑产品的弹窗
const openEditProduct = (data) => {
  if (data.type === 'openEdit') {
    isEdit.value = true
    productId.value = data.productId
    console.log('打开编辑产品的弹窗')
    showButton.value = false
  }
}

// 关闭编辑产品的弹窗
const closeEditProduct = (data) => {
  if (data === 'close') {
    isEdit.value = false
    isAdd.value = false
    console.log('关闭编辑产品')
    showButton.value = true
  }
}

// 根据用户身份，判断左侧tab显示

</script>
<style lang="scss" scoped>
.container {
  background-color: #f8f8f8;
  padding-top: 50px;
  line-height: 1;
  text-align: left;

  &_main {
    width: 1600px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 200px;

    &_left {
      width: 19%;
      background: #ffffff;
      margin-right: 30px;
      border-radius: 8px;
      padding-bottom: 20px;

      &_title {
        padding: 30px 0;
        padding-left: 30px;
        color: #181818;
        font-size: 20px;
        font-weight: 600;
        border-bottom: 1px solid rgba(173, 173, 173, 0.3);
      }

      &_tabtitle {
        color: #181818;
        font-size: 16px;
        padding: 25px 30px;
        border-left: 2px solid #ffffff;
        cursor: pointer;

        &_active {
          color: #27ae60;
          background-color: rgba(39, 174, 96, 0.04);
          border-left: 2px solid #27ae60;
          box-sizing: border-box;
        }
      }
    }

    &_right {
      width: 80%;
      background: #ffffff;
      border-radius: 8px;

      &_title {
        color: #181818;
        font-size: 20px;
        line-height: 44px;
        font-weight: 600;
        border-bottom: 1px solid rgba(173, 173, 173, 0.3);
        padding: 18px 30px;
        display: flex;
        justify-content: space-between;

        &_left {
          display: flex;

          &_search {
            height: 44px;
            border-radius: 6px;
            margin-right: 20px;

            :deep .el-input__wrapper {
              background-color: rgba(173, 173, 173, 0.1);
              border: none;
              box-shadow: none;

            }

            :deep .is-focus {
              box-shadow: 0px 0px 10px 0px rgba(39, 174, 96, 0.1);
              border: 1px solid rgba(39, 174, 96, 1);
              box-sizing: border-box;
            }
          }

          &_add {
            font-size: 14px;
            font-weight: 700;
            height: 44px;
            padding: 15px 30px;
            border-radius: 6px;
            background-color: rgba(39, 174, 96, 1);
            color: #ffffff;
            border: none;
          }
        }
      }

      &_container {
        padding: 30px;
      }

    }
  }
}
</style>
