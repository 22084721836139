<template>
    <div class="user-password">
        <div class="user-password_container">
            <div class="user-password_container_passform">
                <el-form ref="forms" :model="formPass" >
                    <el-form-item label="Original Password" prop="originpass">
                        <el-input v-model="formPass.originpass" placeholder="Enter Original Password"></el-input>
                    </el-form-item>
                    <el-form-item label="New Password" prop="newpassword">
                        <el-input v-model="formPass.newpassword" placeholder="Enter New Password"></el-input>
                    </el-form-item>
                    <el-form-item label="Confirm New Password" prop="renewpassword">
                        <el-input v-model="formPass.renewpassword" placeholder="Enter Confirm New Password"></el-input>
                    </el-form-item>

                    <el-form-item class="el-btn">
                        <el-button type="primary" @click="submitPasswordForm">Save</el-button>
                    </el-form-item>
                </el-form>

            </div>
            <div class="user-password_container_tip">
                Please log in again after changing the password
            </div>
        </div>
    </div>
</template>

<script setup name="Password">
import { reactive, ref } from 'vue'
import { updatePassword } from '@/api/user'
import { getToken, getUserId, removeToken, setUserId } from '@/utils/auth'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
const router = useRouter()
const formPass = reactive({
  originpass: '',
  newpassword: '',
  renewpassword: ''
})
const submitPasswordForm = async () => {
  const userId = getUserId()
  const token = getToken()
  console.log(userId)
  console.log(token)
  const result = await updatePassword(formPass)
  if (result.code === 200) {
    removeToken()
    setUserId(null)
    ElMessage.success('修改成功!')
    router.push({
      path: 'login',
      query: {
        id: 'login'
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/elForm.scss';

.user-password {
    padding: 30px;
    line-height: 1;

    &_container {
        &_passform {
            :deep .el-form {
                //justify-content: center;

                .el-form-item {
                    width: 100%;
                    margin: 0 100px 30px;
                }
            }

            .el-btn {
                width: 830px;
                justify-content: center;
                align-items: center;
                margin: 30px auto 30px !important;
            }
        }

        &_tip {
            font-size: 14px;
            color: rgba(173, 173, 173, 1);
            text-align: center;
            margin-bottom: 60px;
        }
    }

}
</style>
